export const EventIndexSceneTranslation = {
  fi: {
    EventIndexScene: {
      title: 'Tapahtumat',
      emptyText: 'Laitteet eivät ole vielä ilmoittaneet tapahtumia',
      submit: 'Hae',
      filter: 'Haku',
      reset: 'Tyhjennä',
      export: 'Lataa CSV',
    },
  },
};
