import moment from 'moment';
import 'moment/locale/fi';
import { useTranslation } from 'react-i18next';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/CardTitle';
import { HeartbeatLight } from '../../components/heartbeat-lights/HeartbeatLights';
import { useDeviceIndexSceneQuery } from '../../generated/graphql';

// Change moment locale to fi
moment.locale('fi');

export const HomeScene = (): JSX.Element => {
  const { t } = useTranslation('HomeScene');
  const { data, loading } = useDeviceIndexSceneQuery({
    variables: {
      orderBy: [{ heartbeat: 'asc' }],
      take: 5,
    },
  });

  if (loading || !data) {
    return <div className="text-center font-medium text-gray-500">{t('loading')}</div>;
  }

  return (
    <div className="w-full max-w-2xl">
      <Card>
        <CardTitle>{t('title')}</CardTitle>
        <ol className="mt-3 overflow-hidden">
          {/* If devices list is empty show "List is empty" info text */}
          {data.devices.list.length === 0 && (
            <div className="text-center pb-3">
              <span className="text-sm text-gray-500">{t('empty')}</span>
            </div>
          )}

          {/* List through data */}
          {data.devices.list.map((device) => (
            <li className="relative pb-6" key={device.id}>
              <div className="group relative flex items-start">
                <span className="flex h-9 items-center">
                  {/* Show green if heartbeat (date or null) is less than 6h,
                      show yellow if less than 24h,
                      show gray if heartbeat is null
                      else red.  */}
                  <HeartbeatLight
                    color={
                      device.heartbeat
                        ? moment().diff(device.heartbeat, 'hours') < 6
                          ? 'bg-primary'
                          : moment().diff(device.heartbeat, 'hours') < 24
                          ? 'bg-yellow-400 animate-pulse'
                          : 'bg-red-400 animate-pulse'
                        : 'bg-gray-200'
                    }
                  />
                </span>
                <span className="ml-4 flex min-w-0 flex-col">
                  <span className="text-sm font-medium">
                    {t('name')} {device.name}
                  </span>
                  {/* Use heartbeat to calculate when device was previously online.
                      If heartbeat is null show offline.
                      If heartbeat is less than 5 minutes ago show "now".
                      Use moment fromNow. */}
                  <span className="text-sm text-gray-500">
                    {t('active')}{' '}
                    {device.heartbeat
                      ? moment().diff(device.heartbeat, 'minutes') < 5
                        ? t('now')
                        : moment(device.heartbeat).fromNow()
                      : t('offline')}
                  </span>
                </span>
              </div>
            </li>
          ))}
        </ol>
      </Card>
    </div>
  );
};
