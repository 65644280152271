import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthCard } from '../../../components/auth/AuthCard';
import { AuthCardTitle } from '../../../components/auth/AuthCardTitle';
import { AuthError } from '../../../components/auth/AuthError';
import { AuthLayout } from '../../../components/auth/AuthLayout';
import { AuthSubTitle } from '../../../components/auth/AuthSubTitle';
import { ButtonFooter } from '../../../components/auth/ButtonFooter';
import { Loading } from '../../../components/Loading';
import { getRoute } from '../../../config/routes.config';
import { resetPasswordForm, ResetPasswordFormBody } from '../../../forms/auth/reset-password.form';
import {
  useResetPasswordMutation,
  useValidateResetTokenLazyQuery,
} from '../../../generated/graphql';
import { AppFormGenerator } from '../../../releox-engine/react/app-form-generator/AppFormGenerator';
import { ParseUrl } from '../../../utils/parser-url';

export const ResetPasswordScene = () => {
  const { t } = useTranslation('ResetPasswordScene');
  const [token, setToken] = useState('');
  const [hasError, setHasError] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [validateResetToken, { loading: queryLoading, error }] = useValidateResetTokenLazyQuery();

  useEffect(() => {
    (async () => {
      try {
        const validateToken = ParseUrl.parseLocation(location, 'token');

        const response = await validateResetToken({
          variables: { body: { resetPasswordToken: validateToken } },
        });

        if (response.data?.validateResetToken) {
          setToken(validateToken);
        } else {
          setHasError(true);
        }
      } catch (e) {
        setHasError(true);
      }
    })();
  }, [location, setHasError, setToken, validateResetToken]);

  const [reset, { loading }] = useResetPasswordMutation({
    onCompleted: (data) => {
      if (data.resetPassword) {
        navigate(getRoute('RESET_PASSWORD_SUCCESS'));
      }
    },
  });

  const handleSubmit = useCallback(
    (body: ResetPasswordFormBody) => {
      reset({
        variables: {
          body: {
            password: body.password,
            resetPasswordToken: token,
          },
        },
      });
    },
    [reset, token]
  );

  useEffect(() => {
    if (error) {
      setHasError(true);
    }
  }, [error, hasError]);

  if (hasError) {
    return <AuthError loginRoute={getRoute('LOGIN')} errorReason="Invalid token" />;
  }

  if (queryLoading || !token) return <Loading />;

  return (
    <AuthLayout className="py-80" title={t('title')}>
      <AuthCard>
        <div className="mb-5">
          <AuthCardTitle>{t('newPassword')}</AuthCardTitle>
          <AuthSubTitle>{t('fillNewPassword')}</AuthSubTitle>
        </div>
        <AppFormGenerator
          form={resetPasswordForm}
          onSubmit={handleSubmit}
          wrapperClassNames="space-y-3"
          FooterElement={<ButtonFooter loading={loading} buttonText={t('save')} />}
        />
      </AuthCard>
    </AuthLayout>
  );
};
