import _ from 'lodash';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CardTitle } from '../../components/CardTitle';
import { getRoute } from '../../config/routes.config';
import { deviceUserPinForm, DeviceUserPinFormBody } from '../../forms/device-user-pin.form';
import { useUpdateDeviceUserPinMutation } from '../../generated/graphql';
import { useId } from '../../hooks/use-id';
import { useOnComplete } from '../../hooks/use-on-complete';
import { AppFormGenerator } from '../../releox-engine/react/app-form-generator/AppFormGenerator';
import { BackButtonLink } from '../../releox-engine/react/buttons/LinkButton';
import { SubmitButton } from '../../releox-engine/react/buttons/SubmitButton';

export const DeviceUserPinEditScene = (): JSX.Element => {
  const { t } = useTranslation('DeviceUserPinEditScene');

  const onCompleteUpdate = useOnComplete({
    route: 'DEVICE_USER_INDEX',
  });

  const id = useId();

  const [updateDeviceUser, { loading }] = useUpdateDeviceUserPinMutation({
    onCompleted: onCompleteUpdate,
  });

  const handleSubmit = useCallback(
    (body: DeviceUserPinFormBody) => {
      updateDeviceUser({ variables: { body: _.omit(body, 'pinConfirm'), id } });
    },
    [updateDeviceUser, id]
  );

  const Footer = (
    <>
      <BackButtonLink to={getRoute('DEVICE_USER_INDEX')} />
      <SubmitButton loading={loading} />
    </>
  );

  return (
    <>
      <CardTitle>{t('title')}</CardTitle>
      <AppFormGenerator
        FooterElement={Footer}
        wrapperClassNames="space-y-4"
        form={deviceUserPinForm}
        onSubmit={handleSubmit}
      />
    </>
  );
};
