export const HomeSceneTranslation = {
  fi: {
    HomeScene: {
      title: 'Älylääkevaunut',
      name: 'Nimi:',
      active: 'Aktiivinen:',
      offline: 'Offline',
      loading: 'Lataa',
      empty: 'Sinulla ei ole yhtään älylääkevaunua',
      now: 'Aktiivinen',
    },
  },
};
