import { Form, Formik } from 'formik';
import _ from 'lodash';
import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UserFilterContext, UserFilterContextData } from '../../contexts/UserFilterContext';
import { userFilterForm, UserFilterFormBody } from '../../forms/user-filter.form';
import { Button } from '../../releox-engine/react/buttons/Button';
import { Card } from '../Card';
import { CardTitle } from '../CardTitle';

export const UserFilter = () => {
  const { t } = useTranslation('UserIndexScene');
  const [filter, setFilter] = useContext(UserFilterContext);

  const initialValues = useMemo(() => {
    const formInit = _.clone<UserFilterFormBody>(userFilterForm.meta.initValues);

    if (filter.firstName) formInit.firstName = filter.firstName;
    if (filter.lastName) formInit.lastName = filter.lastName;
    if (filter.email) formInit.email = filter.email;

    return formInit;
  }, [filter]);

  const handleSubmit = useCallback(
    (body: UserFilterFormBody) => {
      const query: UserFilterContextData = {};

      if (body.firstName) query.firstName = body.firstName;
      if (body.lastName) query.lastName = body.lastName;
      if (body.email) query.email = body.email;

      setFilter(query);
    },
    [setFilter]
  );
  // handle onReset
  const handleReset = useCallback(() => {
    setFilter({});
  }, [setFilter]);

  return (
    <Card>
      <CardTitle>{t('filter')}</CardTitle>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} onReset={handleReset}>
        <Form className="space-y-3">
          <userFilterForm.Element />
          <Button type="reset">{t('reset')}</Button>
          <Button type="submit" className="float-right">
            {t('submit')}
          </Button>
        </Form>
      </Formik>
    </Card>
  );
};
