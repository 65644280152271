import { useTranslation } from 'react-i18next';
import { Button } from './Button';

type Props = {
  onClick: () => void;
  loading: boolean;
  tNS?: string;
};

export const DeleteButton = ({ onClick, loading }: Props) => {
  const { t } = useTranslation('Common');
  return (
    <>
      <hr className="my-2" />
      <Button
        type="button"
        onClick={onClick}
        loading={loading}
        className="bg-secondary-light text-secondary hover:bg-red-100 hover:text-red-500"
      >
        {t('deleteText')}
      </Button>
    </>
  );
};
