import { eventFilterInput } from '../inputs/event-filter.input';
import { createForm } from '../releox-engine/form/FormGenerator';
import { FormInput } from '../releox-engine/form/FormInput';

const form: FormInput[] = [
  eventFilterInput.userId,
  eventFilterInput.deviceId,
  eventFilterInput.userTag,
];

export const eventFilterForm = createForm(form);

export type EventFilterFormBody = {
  userId: string;
  deviceId: string;
  userTag: string;
};
