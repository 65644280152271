import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { t } from 'i18next';
import { useMemo } from 'react';
import { toast } from 'react-toastify';
import { getRoute } from '../config/routes.config';
import { CustomLogger } from '../utils/CustomLogger';
import { parseGraphqlErrors } from '../utils/get-gql-error';
import { isI18nString } from '../utils/is-i18n-string';
import { useStoredNavigate } from './use-stored-navigate';

const logger = new CustomLogger('useApolloClient');

export const useApolloClient = () => {
  const navigate = useStoredNavigate();

  return useMemo(() => {
    if (!navigate) return null;
    logger.log('module useMemo lifecycle tricked');
    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) => {
          const error = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
          if (message === 'Login expired') {
            navigate(getRoute('LOGOUT'));
          }

          let msg = parseGraphqlErrors(graphQLErrors);
          if (msg === 'Login expired') msg = 'ErrorMessage:loginExpired';
          const localMessage = isI18nString(msg) ? t(msg) : msg;
          toast.error(localMessage);
          // eslint-disable-next-line no-console
          console.log(error);
        });
      if (networkError) {
        const error = `[Network error]: ${networkError}`;
        // eslint-disable-next-line no-console
        console.log(error);
      }
    });

    const host = process.env.REACT_APP_API_URL;

    const uri = `${host}/graphql`;

    const httpLink = createHttpLink({
      uri,
    });

    const authLink = setContext((_, { headers }) => {
      const token = localStorage.getItem('token');
      return {
        headers: {
          ...headers,
          Authorization: token ? `Bearer ${token}` : '',
        },
      };
    });

    const apolloClient = new ApolloClient({
      link: ApolloLink.from([errorLink, authLink.concat(httpLink)]),
      connectToDevTools: false,
      cache: new InMemoryCache(),
      defaultOptions: {
        mutate: {
          errorPolicy: 'all',
        },
        watchQuery: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'ignore',
        },
        query: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'all',
        },
      },
    });

    return apolloClient;
  }, [navigate]);
};
