import { Form, Formik } from 'formik';
import _ from 'lodash';
import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EventFilterContext, EventFilterContextData } from '../../contexts/EventFilterContext';
import { eventFilterForm, EventFilterFormBody } from '../../forms/event-filter.form';
import { Button } from '../../releox-engine/react/buttons/Button';
import { Card } from '../Card';
import { CardTitle } from '../CardTitle';

export const EventFilter = () => {
  const { t } = useTranslation('EventIndexScene');
  const [filter, setFilter] = useContext(EventFilterContext);

  const initialValues = useMemo(() => {
    const formInit = _.clone<EventFilterFormBody>(eventFilterForm.meta.initValues);

    if (filter.userTag) formInit.userTag = filter.userTag;
    if (filter.userId) formInit.userId = `${filter.userId}`;
    if (filter.deviceId) formInit.deviceId = `${filter.deviceId}`;

    return formInit;
  }, [filter]);

  const handleSubmit = useCallback(
    (body: EventFilterFormBody) => {
      const query: EventFilterContextData = {};

      if (body.userTag) query.userTag = body.userTag;
      if (body.userId) query.userId = parseInt(body.userId, 10);
      if (body.deviceId) query.deviceId = parseInt(body.deviceId, 10);

      setFilter(query);
    },
    [setFilter]
  );

  // handle onReset
  const handleReset = useCallback(() => {
    setFilter({});
  }, [setFilter]);

  return (
    <Card>
      <CardTitle>{t('filter')}</CardTitle>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} onReset={handleReset}>
        <Form className="space-y-3">
          <eventFilterForm.Element />
          <Button type="reset">{t('reset')}</Button>
          <Button type="submit" className="float-right">
            {t('submit')}
          </Button>
        </Form>
      </Formik>
    </Card>
  );
};
