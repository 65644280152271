import { createContext } from 'react';
import { WhoAmIQuery } from '../generated/graphql';

const init: WhoAmIQuery['whoAmI'] = {
  id: 0,
  name: '',
};

export const UserContext = createContext<
  [WhoAmIQuery['whoAmI'], (user: WhoAmIQuery['whoAmI']) => void]
>([init, () => ({})]);
