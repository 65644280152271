import { useTranslation } from 'react-i18next';
import { getRoute } from '../../config/routes.config';
import { userForm } from '../../forms/user.form';
import { useInviteUserMutation } from '../../generated/graphql';
import { useOnComplete } from '../../hooks/use-on-complete';
import { useSubmitHandler } from '../../releox-engine/hooks/use-submit-handler/use-submit-handler';
import { AppFormScene } from '../../releox-engine/react/app-form-scene/AppFormScene';

export const UserInviteScene = (): JSX.Element => {
  const { t } = useTranslation('UserInviteScene');
  const onComplete = useOnComplete({ route: 'USER_INDEX' });
  const [createUser, { loading }] = useInviteUserMutation({
    onCompleted: onComplete,
  });

  const handleSubmit = useSubmitHandler(createUser);

  return (
    <AppFormScene
      type="create"
      title={t('title')}
      form={userForm}
      isSubmitLoading={loading}
      backLink={getRoute('USER_INDEX')}
      onSubmit={handleSubmit}
    />
  );
};
