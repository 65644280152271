import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getRoute } from '../config/routes.config';
import { UserContext } from '../contexts/UserContext';
import { useWhoAmILazyQuery, WhoAmIQuery } from '../generated/graphql';
import { useStoredNavigate } from '../hooks/use-stored-navigate';
import { CustomLogger } from '../utils/CustomLogger';
import { AuthError } from './auth/AuthError';
import { Loading } from './Loading';

type Props = {
  children: JSX.Element;
};

const logger = new CustomLogger('RequireAuth');

export const RequireAuth = (props: Props): JSX.Element => {
  const navigate = useStoredNavigate();
  const { i18n } = useTranslation();
  const [isReFetchRequested, setIsReFetchRequested] = useState(false);
  const [user, setUser] = useState<WhoAmIQuery['whoAmI'] | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const [whoAmI, { data }] = useWhoAmILazyQuery();

  useEffect(() => {
    logger.log('Authorization useEffect hook tricked');
    (async () => {
      const token = localStorage.getItem('token');
      if (token) {
        await whoAmI();
        setIsLoading(false);
      } else {
        logger.log('LocalStorage token not existing. Redirect user to login screen without error.');
        navigate(getRoute('LOGIN'));
      }
    })();
  }, [whoAmI, setIsLoading, navigate]);

  useEffect(() => {
    if (data?.whoAmI) {
      setUser(data.whoAmI);
    }
  }, [data, i18n]);

  useEffect(() => {
    if (isReFetchRequested) {
      setIsReFetchRequested(false);
      whoAmI();
    }
  }, [whoAmI, isReFetchRequested, setIsReFetchRequested]);

  if (isLoading) return <Loading />;
  if (!user) return <AuthError loginRoute={getRoute('LOGIN')} errorReason="Unauthorized" />;

  return <UserContext.Provider value={[user, setUser]}>{props.children}</UserContext.Provider>;
};
