import { useEffect, useState } from 'react';
import { useUserIndexSceneQuery } from '../generated/graphql';
import { ReactMultiSelectOption } from '../releox-engine/react/form-inputs/3rd-party/react-select/ReactMultiSelect';

export const useMaintainersReactSelectOptions = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [options, setOptions] = useState<ReactMultiSelectOption[]>([]);

  useUserIndexSceneQuery({
    onCompleted: (data) => {
      if (data.users) {
        setOptions(
          data.users.list.map((user) => ({
            label: user.name,
            value: user.id,
          }))
        );
      }
    },
  });

  useEffect(() => {
    setOptions([]);
  }, []);

  return options;
};
