type Color =
  | 'bg-primary'
  | 'bg-yellow-400 animate-pulse'
  | 'bg-red-400 animate-pulse'
  | 'bg-gray-200';

type Props = {
  color: Color;
};

export const HeartbeatLight = ({ color }: Props): JSX.Element => {
  return (
    <span
      className={`relative z-10 flex h-8 w-8 items-center justify-center rounded-full ${color}`}
    ></span>
  );
};
