import { useTranslation } from 'react-i18next';
import { ButtonLink } from '../../../components/ButtonLink';

type Props = {
  to: string;
};

export const BackButtonLink = ({ to }: Props) => {
  const { t } = useTranslation('Common');

  return <ButtonLink to={to}>{t('back')}</ButtonLink>;
};
