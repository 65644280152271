import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Yup } from '../config/yup.instance';
import { HardwareIdContext } from '../contexts/HardwareIdContext';
import { useMaintainersReactSelectOptions } from '../hooks/use-maintainers-react-select-options';
import { FormInput } from '../releox-engine/form/FormInput';
import { ReactMultiSelectInputValidationSchema } from '../releox-engine/react/form-inputs/3rd-party/react-select/ReactMultiSelect';

const name: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'name',
    label: 'Device:name',
    type: 'text',
  },
};

const isNotificationMuted: FormInput = {
  type: 'checkbox',
  initValue: false,
  validation: Yup.boolean().required(),
  props: {
    name: 'isNotificationMuted',
    label: 'Device:isNotificationMuted',
  },
};

const hardwareId: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'hardwareId',
    label: 'Device:hardwareId',
    type: 'text',
  },
};

const hardwareIdText: FormInput = {
  type: 'element',
  props: {
    type: 'element',
    name: 'hardwareId',
    Element: () => {
      const { t } = useTranslation('Device');
      const hardwareIdValue = useContext(HardwareIdContext);

      return (
        <p>
          {t('hardwareId')}: {hardwareIdValue}
        </p>
      );
    },
  },
};

const maintainers: FormInput = {
  type: 'react-multi-select',
  validation: ReactMultiSelectInputValidationSchema,
  initValue: [],
  props: {
    name: 'maintainers',
    label: 'Device:maintainers',
    useOptions: useMaintainersReactSelectOptions,
  },
};

export const deviceInput = {
  name,
  hardwareIdText,
  isNotificationMuted,
  hardwareId,
  maintainers,
};
