import { deviceInput } from '../inputs/device.input';
import { createForm } from '../releox-engine/form/FormGenerator';
import { FormInput } from '../releox-engine/form/FormInput';

const form: FormInput[] = [deviceInput.name, deviceInput.hardwareId];

export const deviceCreateForm = createForm(form);

export type DeviceFormBody = {
  name: string;
  hardwareId: string;
};
