import { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import { DashboardLayout } from '../components/DashboardLayout';
import { getDashboardRouteConfig } from '../config/routes.config';
import { UserContext } from '../contexts/UserContext';
import { useSideBarMenu } from '../hooks/use-sidebar-menu';
import { DepartmentCreateScene } from '../scenes/department/DepartmentCreateScene';
import { DepartmentEditScene } from '../scenes/department/DepartmentEditScene';
import { DepartmentIndexScene } from '../scenes/department/DepartmentIndexScene';
import { DeviceUserCreateScene } from '../scenes/device-user/DeviceUserCreateScene';
import { DeviceUserIndexScene } from '../scenes/device-user/DeviceUserIndexScene';
import { DeviceCreateScene } from '../scenes/device/DeviceCreateScene';
import { DeviceEditScene } from '../scenes/device/DeviceEditScene';
import { DeviceIndexScene } from '../scenes/device/DeviceIndexScene';
import { EventIndexScene } from '../scenes/event/EventIndexScene';
import { HomeScene } from '../scenes/home/HomeScene';
import { ChangePasswordScene } from '../scenes/password/ChangePasswordScene';
import { UserEditScene } from '../scenes/user/UserEditScene';
import { UserIndexScene } from '../scenes/user/UserIndexScene';
import { UserInviteScene } from '../scenes/user/UserInviteScene';
import { DeviceUserEditRouter } from './DeviceUserEditRouter';

const routes = getDashboardRouteConfig();

export const DashboardRouter = () => {
  const [user] = useContext(UserContext);
  const nav = useSideBarMenu();

  return (
    <DashboardLayout user={user} nav={nav} homeUrl={routes.DASHBOARD}>
      <Routes>
        <Route path={routes.DASHBOARD} element={<HomeScene />} />
        <Route path={routes.DEVICE_INDEX} element={<DeviceIndexScene />} />
        <Route path={routes.DEVICE_CREATE} element={<DeviceCreateScene />} />
        <Route path={routes.DEVICE_EDIT} element={<DeviceEditScene />} />
        <Route path={routes.EVENT_INDEX} element={<EventIndexScene />} />
        <Route path={routes.DEPARTMENT_INDEX} element={<DepartmentIndexScene />} />
        <Route path={routes.DEPARTMENT_CREATE} element={<DepartmentCreateScene />} />
        <Route path={routes.DEPARTMENT_EDIT} element={<DepartmentEditScene />} />
        <Route path={routes.DEVICE_USER_INDEX} element={<DeviceUserIndexScene />} />
        <Route path={routes.DEVICE_USER_CREATE} element={<DeviceUserCreateScene />} />
        <Route path={routes.DEVICE_USER_EDIT} element={<DeviceUserEditRouter />} />
        <Route path={routes.USER_INDEX} element={<UserIndexScene />} />
        <Route path={routes.USER_INVITE} element={<UserInviteScene />} />
        <Route path={routes.USER_EDIT} element={<UserEditScene />} />
        <Route path={routes.CHANGE_PASSWORD} element={<ChangePasswordScene />} />
      </Routes>
    </DashboardLayout>
  );
};
