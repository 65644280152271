import { useParams } from 'react-router-dom';

/**
 * get or throw `id` param.
 * @returns {string} id
 */
export const useId = (): number => {
  const { id } = useParams();
  if (!id) throw new Error('Missing id');
  return parseInt(id, 10);
};
