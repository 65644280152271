export const deviceTranslation = {
  fi: {
    Device: {
      id: '#',
      name: 'Nimi',
      hardwareId: 'Laitetunnus',
      heartbeat: 'Heräteaika',
      isNotificationMuted: 'Hiljennä hälytykset',
      maintainers: 'Huoltovastaavat'
    },
  },
};
