import { userInput } from '../inputs/user.input';
import { createForm } from '../releox-engine/form/FormGenerator';
import { FormInput } from '../releox-engine/form/FormInput';

const form: FormInput[] = [userInput.password, userInput.passwordConfirm];

export const passwordForm = createForm(form);

export type PasswordFormBody = {
  password: string;
  passwordConfirm: string;
};
