import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { ParseUrl } from '../utils/parser-url';
import { Loading } from './Loading';

type Props = {
  children: JSX.Element;
};

export const RequireGuest = (props: Props): JSX.Element => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { i18n } = useTranslation();

  const location = useLocation();
  const lang = useMemo(() => ParseUrl.parseOptionalLocation(location, 'lang'), [location]);

  useEffect(() => {
    if (lang && i18n.resolvedLanguage !== lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang, i18n]);

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      navigate('/');
    }
    setIsLoading(false);
  }, [navigate]);

  if (isLoading) return <Loading />;

  return props.children;
};
