import {
  GeneratableReactMultiSelectInput,
  GeneratableReactMultiSelectInputOptions,
} from '../react/form-inputs/3rd-party/react-select/ReactMultiSelect';
import {
  GeneratableReactSelectInput,
  GeneratableReactSelectInputOptions,
} from '../react/form-inputs/3rd-party/react-select/ReactSelectInput';
import {
  GeneratableArrayInput,
  GeneratableArrayInputOptions,
} from '../react/form-inputs/ArrayInput';
import {
  GeneratableCheckboxInput,
  GeneratableCheckboxInputOptions,
} from '../react/form-inputs/Checkbox';
import {
  GeneratableReleoxFormElement,
  GeneratableReleoxFormElementOptions,
} from '../react/form-inputs/ReleoxFormElement';
import {
  GeneratableSelectInput,
  GeneratableSelectInputOptions,
} from '../react/form-inputs/SelectInput';
import {
  GeneratableTextareaInput,
  GeneratableTextareaInputOptions,
} from '../react/form-inputs/TextareaInput';
import { GeneratableTextInput, GeneratableTextInputOptions } from '../react/form-inputs/TextInput';

export type FormInput =
  | GeneratableTextInputOptions
  | GeneratableTextareaInputOptions
  | GeneratableSelectInputOptions
  | GeneratableArrayInputOptions
  | GeneratableCheckboxInputOptions
  | GeneratableReleoxFormElementOptions
  | GeneratableReactMultiSelectInputOptions
  | GeneratableReactSelectInputOptions;

export const FormInputs = [
  GeneratableTextInput,
  GeneratableCheckboxInput,
  GeneratableSelectInput,
  GeneratableTextareaInput,
  GeneratableArrayInput,
  GeneratableReleoxFormElement,
  GeneratableReactMultiSelectInput,
  GeneratableReactSelectInput,
];
