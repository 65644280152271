export const sidebarTranslation = {
  fi: {
    SideBar: {
      home: 'Etusivu',
      devices: 'Älylääkevaunut',
      departments: 'Osastot',
      deviceUsers: 'Käyttäjät',
      users: 'Adminit',
      events: 'Tapahtumat',
      changePassword: 'Vaihda salasana',
      logout: 'Kirjaudu ulos',
    },
  },
};
