import * as Yup from 'yup';
import { useDeviceReactSelectOptions } from '../hooks/DeviceReactSelectOptions';
import { useDeviceUserReactSelectOptions } from '../hooks/DeviceUserReactSelectOptions';
import { FormInput } from '../releox-engine/form/FormInput';

const userId: FormInput = {
  type: 'react-select',
  initValue: '',
  validation: Yup.string(),
  props: {
    name: 'userId',
    label: 'EventFilter:userId',
    useOptions: useDeviceUserReactSelectOptions,
  },
};

const deviceId: FormInput = {
  type: 'react-select',
  initValue: '',
  validation: Yup.string(),
  props: {
    name: 'deviceId',
    label: 'EventFilter:deviceId',
    useOptions: useDeviceReactSelectOptions,
  },
};

const userTag: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string(),
  props: {
    name: 'userTag',
    label: 'EventFilter:userTag',
    type: 'text',
  },
};

export const eventFilterInput = {
  userId,
  deviceId,
  userTag,
};
