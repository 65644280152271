export const UserIndexSceneTranslation = {
  fi: {
    UserIndexScene: {
      title: 'Adminit',
      create: 'Kutsu uusi admin',
      submit: 'Hae',
      filter: 'Haku',
      reset: 'Tyhjennä',
      emptyText: 'Voit lisätä uuden Admin käyttäjän sivun oikeasta ylälaidasta.',
    },
  },
};
