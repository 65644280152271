import { userInput } from '../inputs/user.input';
import { createForm } from '../releox-engine/form/FormGenerator';
import { FormInput } from '../releox-engine/form/FormInput';

const form: FormInput[] = [userInput.firstName, userInput.lastName, userInput.email];

export const userForm = createForm(form);

export type UserFormBody = {
  firstName: string;
  lastName: string;
  email: string;
};
