import { EventIndexSceneQuery } from '../generated/graphql';

type Props = {
  event: EventIndexSceneQuery['events']['list'][0];
};

export const LogMessage = ({ event }: Props): JSX.Element => {
  if (event.event === 'DISTRIBUTOR_OPEN_ALL') {
    return (
      <>
        Jakelija <span className="text-primary-600">{event.userName}</span> avasi kaikki luukut
        laitteesta <span className="text-primary-600">{event.deviceName}</span>
      </>
    );
  }

  return (
    <>
      <span className="text-primary-600">{event.userName}</span> avasi luukun{' '}
      <span className="text-primary-600">
        {(event.lockerIndex == undefined ? -10 : event.lockerIndex) + 1}
      </span>{' '}
      laitteesta <span className="text-primary-600">{event.deviceName}</span> osastolta{' '}
      <span className="text-primary-600">{event.departmentName}</span>
    </>
  );
};
