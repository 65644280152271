import { useTranslation } from 'react-i18next';
import { getRoute } from '../../config/routes.config';
import { deviceCreateForm } from '../../forms/device-create.form';
import { useCreateDeviceMutation } from '../../generated/graphql';
import { useOnComplete } from '../../hooks/use-on-complete';
import { useSubmitHandler } from '../../releox-engine/hooks/use-submit-handler/use-submit-handler';
import { AppFormScene } from '../../releox-engine/react/app-form-scene/AppFormScene';

export const DeviceCreateScene = (): JSX.Element => {
  const { t } = useTranslation('DeviceCreateScene');
  const onComplete = useOnComplete({
    route: 'DEVICE_INDEX',
  });
  const [createDevice, { loading }] = useCreateDeviceMutation({ onCompleted: onComplete });

  const handleSubmit = useSubmitHandler(createDevice);

  return (
    <AppFormScene
      type="create"
      title={t('title')}
      form={deviceCreateForm}
      backLink={getRoute('DEVICE_INDEX')}
      onSubmit={handleSubmit}
      isSubmitLoading={loading}
    />
  );
};
