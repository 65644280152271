import { Link } from 'react-router-dom';
import { joinClassNames } from '../utils/join-class-names';

type Props = {
  to: string;
  children: string;
  className?: string;
};

export const ButtonLink = ({ to, children, className }: Props) => {
  const disabled = 'disabled:bg-gray-300 disabled:text-white';
  const hover = 'hover:bg-primary-500';

  return (
    <Link
      to={to}
      className={joinClassNames(
        `shadow-sm inline-block text-white text-sm bg-primary py-2 px-3`,
        disabled,
        className,
        hover
      )}
    >
      {children}
    </Link>
  );
};
