import { deviceUserInput } from '../inputs/device-user.input';
import { createForm } from '../releox-engine/form/FormGenerator';
import { FormInput } from '../releox-engine/form/FormInput';

const form: FormInput[] = [deviceUserInput.pin, deviceUserInput.pinConfirm];

export const deviceUserPinForm = createForm(form);

export type DeviceUserPinFormBody = {
  pin: string;
  pinConfirm: string;
};
