import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../releox-engine/react/buttons/Button';

type Props = {
  loading: boolean;
  buttonText: string;
  links?: {
    text: string;
    to: string;
  }[];
};

export const ButtonFooter = ({ loading, buttonText, links }: Props) => {
  const Links = useMemo(
    () =>
      (links || []).map((link) => (
        <Link
          key={link.to}
          to={link.to}
          className="text-primary hover:text-primary-500 block text-sm"
        >
          {link.text}
        </Link>
      )),
    [links]
  );

  return (
    <div className="md:grid md:grid-cols-2 pt-3">
      <div className="hidden md:block">{Links}</div>
      <div className="md:text-right">
        <Button loading={loading} type="submit" className="w-full md:w-auto">
          {buttonText}
        </Button>
      </div>
      <div className="block md:hidden mt-4 md:mt-0">{Links}</div>
    </div>
  );
};
