import { ErrorMessage as Error } from 'formik';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { isI18nString } from '../../../utils/is-i18n-string';

type Props = {
  name: string;
};

export const ErrorMessage = ({ name }: Props) => {
  return (
    <Error render={(msg) => <RawErrorMessage name={name}>{msg}</RawErrorMessage>} name={name} />
  );
};

type RawProps = {
  children: string;
  name: string;
};

export const RawErrorMessage = ({ children, name }: RawProps) => {
  const { t } = useTranslation();
  let value = '';

  if (isI18nString(children)) {
    const x = /\d+/.exec(children);
    const number = _.first(x);
    if (number) {
      value = number;
      children = children.split(' ')[0];
    }
  }

  return <span className="text-red-400 text-sm">{t(children, { field: name, value })}</span>;
};
