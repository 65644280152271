export const DeviceUserIndexSceneTranslation = {
  fi: {
    DeviceUserIndexScene: {
      title: 'Käyttäjät',
      create: 'Luo uusi käyttäjä',
      emptyText: 'Voit lisätä uuden käyttäjän sivun oikeasta ylälaidasta.',
      submit: 'Hae',
      filter: 'Haku',
      reset: 'Tyhjennä',
    },
  },
};
