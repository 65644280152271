import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AuthCard } from '../../../components/auth/AuthCard';
import { AuthCardTitle } from '../../../components/auth/AuthCardTitle';
import { AuthLayout } from '../../../components/auth/AuthLayout';
import { AuthSubTitle } from '../../../components/auth/AuthSubTitle';
import { ButtonFooter } from '../../../components/auth/ButtonFooter';
import { getRoute } from '../../../config/routes.config';
import { forgotForm, ForgotFormBody } from '../../../forms/auth/forgot.form';
import { useForgotPasswordMutation } from '../../../generated/graphql';
import { AppFormGenerator } from '../../../releox-engine/react/app-form-generator/AppFormGenerator';

export const ForgotScene = () => {
  const { t } = useTranslation('ForgotScene');
  const navigate = useNavigate();

  const [forgot, { loading }] = useForgotPasswordMutation({
    onCompleted: (data) => {
      if (data.forgotPassword) {
        navigate(getRoute('FORGOT_SUCCESS'));
      }
    },
  });

  const handleForgot = useCallback(
    (body: ForgotFormBody) => {
      forgot({ variables: { body } });
    },
    [forgot]
  );

  return (
    <AuthLayout className="py-80" title={t('resetPassword')}>
      <AuthCard>
        <div className="mb-5">
          <AuthCardTitle>{t('resetPassword')}</AuthCardTitle>
          <AuthSubTitle>{t('fillYourEmail')}</AuthSubTitle>
        </div>
        <AppFormGenerator
          form={forgotForm}
          onSubmit={handleForgot}
          wrapperClassNames="space-y-3"
          FooterElement={
            <ButtonFooter
              loading={loading}
              buttonText={t('resetPasswordBtn')}
              links={[
                {
                  to: getRoute('LOGIN'),
                  text: t('backToLogin'),
                },
              ]}
            />
          }
        />
      </AuthCard>
    </AuthLayout>
  );
};
