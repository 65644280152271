import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthCard } from '../../../components/auth/AuthCard';
import { AuthCardTitle } from '../../../components/auth/AuthCardTitle';
import { AuthLayout } from '../../../components/auth/AuthLayout';
import { ButtonFooter } from '../../../components/auth/ButtonFooter';
import { getRoute } from '../../../config/routes.config';
import { loginForm } from '../../../forms/auth/login.form';
import { LoginMutation, useLoginMutation } from '../../../generated/graphql';
import { useOnComplete } from '../../../hooks/use-on-complete';
import { useSubmitHandler } from '../../../releox-engine/hooks/use-submit-handler/use-submit-handler';
import { AppFormGenerator } from '../../../releox-engine/react/app-form-generator/AppFormGenerator';

export const LoginScene = () => {
  const { t } = useTranslation('LoginScene');

  const loginUser = useCallback((data: LoginMutation) => {
    localStorage.setItem('token', data.login.token);
  }, []);

  const onComplete = useOnComplete({
    route: 'DASHBOARD',
    callback: loginUser,
    notification: 'none',
  });

  const [login, { loading }] = useLoginMutation({
    onCompleted: onComplete,
  });

  const handleLogin = useSubmitHandler(login);

  return (
    <AuthLayout title={t('login')}>
      <AuthCard>
        <div className="mb-5">
          <AuthCardTitle>{t('login')}</AuthCardTitle>
        </div>
        <AppFormGenerator
          form={loginForm}
          onSubmit={handleLogin}
          wrapperClassNames="space-y-3"
          FooterElement={
            <ButtonFooter
              buttonText={t('login')}
              loading={loading}
              links={[
                {
                  to: getRoute('FORGOT'),
                  text: t('forgotPassword'),
                },
              ]}
            />
          }
        />
      </AuthCard>
    </AuthLayout>
  );
};
