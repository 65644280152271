import { useField } from 'formik';
import * as Yup from 'yup';
import { ReleoxBaseInputProps } from '../../form/ReleoxBaseInputProps';
import { Label } from './Label';

export interface CheckboxInputProps extends ReleoxBaseInputProps {
  label: string;
}

export const CheckboxInput = (props: CheckboxInputProps) => {
  const [field, meta] = useField(props);
  const { name, label } = props;
  const id = `${name}-input`;

  return (
    <fieldset className="space-y-5">
      <div className="relative flex items-start">
        <div className="flex items-center h-5">
          <input
            {...field}
            id={id}
            checked={field.value}
            type="checkbox"
            className="focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded"
          />
        </div>
        <div className="ml-3 text-sm">
          <Label htmlFor={id} meta={meta}>
            {label}
          </Label>
        </div>
      </div>
    </fieldset>
  );
};

export interface GeneratableCheckboxInputOptions {
  type: 'checkbox';
  initValue: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validation: Yup.BooleanSchema;
  props: CheckboxInputProps;
}

export const GeneratableCheckboxInput = {
  Element: CheckboxInput,
  type: 'checkbox',
};
