import { useField } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import * as Yup from 'yup';
import { ReleoxBaseInputProps } from '../../../../form/ReleoxBaseInputProps';
import { ErrorMessage } from '../../ErrorMessage';
import { InputWrapper } from '../../InputWrapper';
import { Label } from '../../Label';
import { ReactMultiSelectOption } from './ReactMultiSelect';

export interface ReactSelectInputProps extends ReleoxBaseInputProps {
  label: string;
  useOptions: () => ReactMultiSelectOption[] | null;
}

interface Props extends ReactSelectInputProps {
  options: {
    value: string;
    label: string;
  };
}

export const ReactSelectInput = (props: Props) => {
  const { t } = useTranslation('ReactSelectInput');
  const options = props.useOptions();
  const [defaultValue, setDefaultValue] = useState<Props['options'] | undefined>();
  const [field, meta, helpers] = useField(props);
  const { name, label } = props;
  const id = `${name}-input`;

  const handleDefaultValue = useCallback(() => {
    setDefaultValue(meta.initialValue);
  }, [meta.initialValue]);

  useEffect(() => {
    handleDefaultValue();
  }, [handleDefaultValue]);

  const placeholder = useMemo(() => {
    let text = '';
    if (props.label) text = t(props.label);
    if (props.placeholder) text = t(props.placeholder);
    return <>{text}</>;
  }, [props.placeholder, props.label, t]);

  return (
    <div key={`${JSON.stringify(defaultValue)}-${JSON.stringify(meta.value)}`}>
      <Label htmlFor={id} meta={meta}>
        {label}
      </Label>
      {options !== null ? (
        <InputWrapper>
          <Select
            className="text-sm"
            placeholder={placeholder}
            classNamePrefix="select"
            defaultValue={options.find((o) => o.value === field.value)}
            isClearable
            isSearchable
            name={field.name}
            noOptionsMessage={() => t('ReactSelectInput:noOptionsMessage')}
            options={options}
            onChange={(value) => helpers.setValue(value ? value.value : '')}
          />

          <ErrorMessage name={field.name} />
        </InputWrapper>
      ) : (
        <p>{t('loading')}</p>
      )}
    </div>
  );
};

export interface GeneratableReactSelectInputOptions {
  type: 'react-select';
  initValue: string;
  validation: Yup.StringSchema;
  props: ReactSelectInputProps;
}

export const GeneratableReactSelectInput = {
  Element: ReactSelectInput,
  type: 'react-select',
};
