import logo from '../../images/mediclaudo.png';
import { BackgroundClass } from '../BackgroundClass';
import { Page } from '../Page';

interface Props {
  children: JSX.Element | JSX.Element[];
  title: string;
  className?: string;
  hideLogo?: boolean;
}

export const AuthLayout = (props: Props) => {
  const { children, className, title, hideLogo } = props;

  let cls = '-mt-20';

  if (hideLogo) {
    cls = '';
  }

  return (
    <>
      <BackgroundClass className="background-image" />
      <div className={`flex h-screen justify-center items-center ${className}`}>
        <div className={`w-192 ${cls} px-4`}>
          <Page title={title}>
            <>
              {!hideLogo ? (
                <div className="mb-4">
                  <img className="mx-auto h-16 w-auto" src={logo} alt="Logo" />
                </div>
              ) : null}
              {children}
            </>
          </Page>
        </div>
      </div>
    </>
  );
};
