export const ValidationTranslation = {
  fi: {
    Validation: {
      min: 'arvon on oltava vähintään {{value}} merkkiä pitkä',
      max: 'arvon on oltava enintään {{value}} merkkiä pitkä',
      require: 'pakollinen kenttä',
      email: 'arvon on oltava kelvollinen sähköpostiosoite',
      numberOnly: 'arvo saa olla ainostaan numeroita',
      match: 'arvot eivät täsmää',
    },
  },
};
