import { ErrorMessage, useField } from 'formik';
import * as _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import * as Yup from 'yup';
import { ReleoxBaseInputProps } from '../../../../form/ReleoxBaseInputProps';
import { RawErrorMessage } from '../../ErrorMessage';
import { InputWrapper } from '../../InputWrapper';
import { Label } from '../../Label';

export type ReactMultiSelectOption = {
  value: number;
  label: string;
};

export interface ReactMultiSelectInputProps extends ReleoxBaseInputProps {
  label: string;
  useOptions: () => ReactMultiSelectOption[];
}

interface Props extends ReactMultiSelectInputProps {
  options: ReactMultiSelectOption[];
}

export const ReactMultiSelectInputValidationSchema = Yup.array()
  .of(
    Yup.object()
      .shape({
        label: Yup.string().required(),
        value: Yup.number().required(),
      })
      .required()
  )
  .required();

export const ReactMultiSelectInput = (props: Props) => {
  const { t } = useTranslation();
  const options = props.useOptions();
  const [defaultValue, setDefaultValue] = useState<Props['options'] | undefined>();
  const [field, meta, helpers] = useField(props);
  const { name, label } = props;
  const id = `${name}-input`;

  const handleDefaultValue = useCallback(() => {
    setDefaultValue(meta.initialValue);
  }, [meta.initialValue]);

  useEffect(() => {
    handleDefaultValue();
  }, [handleDefaultValue]);

  const placeholder = useMemo(() => {
    let text = '';
    if (props.label) text = t(props.label);
    if (props.placeholder) text = t(props.placeholder);
    return <>{text}</>;
  }, [props.placeholder, props.label, t]);

  return (
    <div key={JSON.stringify(defaultValue)}>
      <Label htmlFor={id} meta={meta}>
        {label}
      </Label>
      <InputWrapper>
        <Select
          placeholder={placeholder}
          classNamePrefix="select"
          defaultValue={defaultValue}
          isClearable
          isMulti
          isSearchable
          noOptionsMessage={() => t('ReactSelectInput:noOptionsMessage')}
          name={field.name}
          options={options}
          onChange={(value) => helpers.setValue(value)}
        />
        <ErrorMessage name={field.name}>
          {(errors) => {
            const error = _.first(errors);
            const msg = `${_.get(error, 'label')}, ${_.get(error, 'value')}`;
            return <RawErrorMessage name={field.name}>{msg}</RawErrorMessage>;
          }}
        </ErrorMessage>
      </InputWrapper>
    </div>
  );
};

export interface GeneratableReactMultiSelectInputOptions {
  type: 'react-multi-select';
  initValue: ReactMultiSelectOption[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validation: Yup.ArraySchema<Yup.ObjectSchema<any>>;
  props: ReactMultiSelectInputProps;
}

export const GeneratableReactMultiSelectInput = {
  Element: ReactMultiSelectInput,
  type: 'react-multi-select',
};
