export const AcceptInvitationSceneTranslation = {
  fi: {
    AcceptInvitationScene: {
      newPassword: 'Uusi salasana',
      fillNewPassword: 'Kirjoita uusi salasana.',
      save: 'Tallenna',
    },
  },
  en: {
    AcceptInvitationScene: {
      newPassword: 'New password',
      fillNewPassword: 'Enter a new password',
      save: 'Save',
    },
  },
};
