import * as yup from 'yup';

yup.setLocale({
  mixed: {
    required: 'Validation:require',
  },
  string: {
    email: 'Validation:email',
    min: 'Validation:min ${min}',
    max: 'Validation:max ${max}',
  },
});

export const Yup = yup;
