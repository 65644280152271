import { useState } from 'react';
import { useDeviceIndexSceneQuery } from '../generated/graphql';
import { ReactMultiSelectOption } from '../releox-engine/react/form-inputs/3rd-party/react-select/ReactMultiSelect';

export const useDeviceReactSelectOptions = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [options, setOptions] = useState<ReactMultiSelectOption[] | null>(null);

  useDeviceIndexSceneQuery({
    onCompleted: (data) => {
      if (data.devices) {
        setOptions(
          data.devices.list.map((device) => ({
            label: device.name,
            value: device.id,
          }))
        );
      }
    },
  });

  return options;
};
