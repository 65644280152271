import { deleteTranslation } from '../../utils/delete-translation';

export const DeviceEditSceneTranslation = {
  fi: {
    DeviceEditScene: {
      title: 'Muokkaa älylääkevaunun tietoja',
      areYouSure: deleteTranslation('älylääkevaunun'),
    },
  },
};
