import { deleteTranslation } from '../../utils/delete-translation';

export const UserEditSceneTranslation = {
  fi: {
    UserEditScene: {
      title: 'Muokkaa adminia',
      areYouSure: deleteTranslation('adminin'),
    },
  },
};
