import { userFilterInput } from '../inputs/user-filter.input';
import { createForm } from '../releox-engine/form/FormGenerator';
import { FormInput } from '../releox-engine/form/FormInput';

const form: FormInput[] = [
  userFilterInput.firstName,
  userFilterInput.lastName,
  userFilterInput.email,
];

export const userFilterForm = createForm(form);

export type UserFilterFormBody = {
  firstName: string;
  lastName: string;
  email: string;
};
