import { useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

export const useStoredNavigate = () => {
  const navigateFunction = useNavigate();

  const [navigate] = useState<NavigateFunction>(() => navigateFunction);

  return navigate;
};
