import { CardTitle } from '../../../components/CardTitle';
import { FormLayout } from '../../../components/FormLayout';
import { Loading } from '../../../components/Loading';

type Config = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Layout: (props: any) => JSX.Element;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Title: (props: any) => JSX.Element;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Loading: (props: any) => JSX.Element;
};

export const appFormSceneConfig: Config = {
  Layout: FormLayout,
  Title: CardTitle,
  Loading: Loading,
};
