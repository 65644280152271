import { Helmet } from 'react-helmet';

interface Props {
  children: JSX.Element | JSX.Element[];
  title: string;
}

export const Page = (props: Props) => {
  const { children, title } = props;

  return (
    <>
      <Helmet>
        <title>{title} | Mediclaudo</title>
      </Helmet>
      {children}
    </>
  );
};
