import { FieldMetaProps } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  htmlFor: string;
  children: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta: FieldMetaProps<any>;
};

export const Label = ({ htmlFor, children, meta }: Props) => {
  const { t } = useTranslation();
  let labelClasses = "block text-sm font-medium text-gray-700";

  if (meta.error && meta.touched) {
    labelClasses += " text-red-color";
  }
  return (
    <label htmlFor={htmlFor} className={labelClasses}>
      {t(children)}
    </label>
  );
};
