export const ResetPasswordSceneTranslation = {
  fi: {
    ResetPasswordScene: {
      title: 'Vaihda salasana',
      save: 'Tallenna',
      newPassword: 'Kirjoita uusi salasana',
      fillNewPassword: 'Kirjoita uusi salasanasi ja paina tallenna.',
    },
  },
  en: {
    ResetPasswordScene: {
      title: 'Change password',
      save: 'Save',
      newPassword: 'Enter a new password',
      fillNewPassword: 'Enter your new password and press save.',
    },
  },
};
