import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { getRoute } from '../../config/routes.config';
import { HardwareIdContext } from '../../contexts/HardwareIdContext';
import { deviceEditForm, DeviceEditFormBody } from '../../forms/device-edit.form';
import {
  useDeleteDeviceMutation,
  useDeviceEditSceneQuery,
  useUpdateDeviceMutation,
} from '../../generated/graphql';
import { useId } from '../../hooks/use-id';
import { useOnComplete } from '../../hooks/use-on-complete';
import { AppFormScene } from '../../releox-engine/react/app-form-scene/AppFormScene';

export const DeviceEditScene = (): JSX.Element => {
  const { t } = useTranslation('DeviceEditScene');
  const id = useId();

  const onUpdateComplete = useOnComplete({
    route: 'DEVICE_INDEX',
  });

  const onDeleteComplete = useOnComplete({
    route: 'DEVICE_INDEX',
    notification: 'deleted',
  });

  const query = useDeviceEditSceneQuery({ variables: { id } });

  const [createDevice, { loading }] = useUpdateDeviceMutation({ onCompleted: onUpdateComplete });
  const [deleteDevice, { loading: deleteLoading }] = useDeleteDeviceMutation({
    variables: { id },
    onCompleted: onDeleteComplete,
  });

  const handleSubmit = useCallback(
    ({ maintainers, ...form }: DeviceEditFormBody) => {
      const body = {
        ...form,
        maintainers: maintainers.map((maintainer) => maintainer.value),
      };

      createDevice({
        variables: {
          id,
          body: body,
        },
      });
    },
    [id, createDevice]
  );

  return (
    <HardwareIdContext.Provider value={query.data?.device.hardwareId}>
      <AppFormScene
        type="edit"
        query={query}
        queryDataPath="device"
        title={t('title')}
        form={deviceEditForm}
        backLink={getRoute('DEVICE_INDEX')}
        onSubmit={handleSubmit}
        isSubmitLoading={loading || deleteLoading}
        onDelete={deleteDevice}
        onDeletePrompt={t('areYouSure')}
      />
    </HardwareIdContext.Provider>
  );
};
