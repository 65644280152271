import { createContext, useState } from 'react';
import { ChildrenProp } from '../types/ChildrenProp';
import { FilterContext } from '../types/FilterContext';

export type EventFilterContextData = {
  userId?: number;
  deviceId?: number;
  userTag?: string;
};

export const EventFilterContext = createContext<FilterContext<EventFilterContextData>>([
  {},
  () => ({}),
]);

export const EventFilterContextProvider = ({ children }: ChildrenProp) => {
  const state = useState<EventFilterContextData>({});

  return <EventFilterContext.Provider value={state}>{children}</EventFilterContext.Provider>;
};
