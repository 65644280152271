const routesConfig = {
  DASHBOARD: '/',
  LOGOUT: '/logout',

  DEVICE_INDEX: '/devices',
  DEVICE_EDIT: '/devices/:id/edit',
  DEVICE_CREATE: '/devices/new',

  EVENT_INDEX: '/events',

  DEVICE_USER_INDEX: '/device-users',
  DEVICE_USER_EDIT: '/device-users/:id/edit/:page',
  DEVICE_USER_CREATE: '/device-users/new',

  CHANGE_PASSWORD: '/change-password',

  USER_INDEX: '/users',
  USER_EDIT: '/users/:id/edit',
  USER_INVITE: '/users/invite',

  DEPARTMENT_INDEX: '/departments',
  DEPARTMENT_EDIT: '/departments/:id/edit',
  DEPARTMENT_CREATE: '/departments/new',

  LOGIN: '/login',
  FORGOT: '/forgot',
  FORGOT_SUCCESS: '/forgot-success',
  RESET_PASSWORD: '/reset',
  RESET_PASSWORD_SUCCESS: '/reset-password-success',
  ACCEPT_INVITATION: '/accept-invitation',
};

export type RouteKey = keyof typeof routesConfig;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getRoute = (arg: RouteKey, params?: any, skipValidation = false): string => {
  let url = routesConfig[arg];

  if (params) {
    const keys = Object.keys(params);

    const paramsLength = (url.match(/:/g) || []).length;

    if (!skipValidation) {
      if (keys.length !== paramsLength)
        throw new Error(
          `[getRoute] ${arg} route params and arguments mismatch. ${keys.join(', ')}`
        );
    }

    keys.forEach((p) => {
      url = url.replace(`:${p}`, params[p]);
    });
  }

  if (url.includes(':')) throw new Error(`[getRoute] ${url} route missing params`);

  return url;
};

export const getDashboardRouteConfig = () => {
  return routesConfig;
};
