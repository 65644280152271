import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Loading } from '../../../components/Loading';
import { getRoute } from '../../../config/routes.config';

export const LogoutScene = (): JSX.Element => {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem('token');
    navigate(getRoute('LOGIN'));
  }, [navigate]);

  return <Loading />;
};
