import {
    ArchiveIcon,
    HomeIcon,
    LockClosedIcon,
    LogoutIcon,
    OfficeBuildingIcon,
    ServerIcon,
    SupportIcon,
    UsersIcon
} from '@heroicons/react/outline';
import { List } from 'immutable';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { getRoute } from '../config/routes.config';
import { UserContext } from '../contexts/UserContext';

export type SideBarDropdown = {
  name: string;
  href: string;
  icon: typeof HomeIcon;
  links: SideBarLink[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  show?: (user: any) => boolean;
};

export type SideBarLink = {
  name: string;
  href: string;
  icon?: typeof HomeIcon;
  current?: boolean;
  preventActive?: boolean;
  exact?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  show?: (user: any) => boolean;
};

export type SideBarNavItem = SideBarLink | SideBarDropdown;

const sidebarConfig: SideBarNavItem[] = [
  {
    name: 'home',
    href: getRoute('DASHBOARD'),
    icon: HomeIcon,
    exact: true,
  },
  {
    name: 'devices',
    href: getRoute('DEVICE_INDEX'),
    icon: ServerIcon,
  },
  {
    name: 'events',
    href: getRoute('EVENT_INDEX'),
    icon: ArchiveIcon,
  },
  {
    name: 'departments',
    href: getRoute('DEPARTMENT_INDEX'),
    icon: OfficeBuildingIcon,
  },
  {
    name: 'deviceUsers',
    href: getRoute('DEVICE_USER_INDEX'),
    icon: UsersIcon,
  },
  {
    name: 'users',
    href: getRoute('USER_INDEX'),
    icon: SupportIcon,
  },
  {
    name: 'changePassword',
    href: getRoute('CHANGE_PASSWORD'),
    icon: LockClosedIcon,
  },
  {
    name: 'logout',
    href: getRoute('LOGOUT'),
    icon: LogoutIcon,
  },
];

export const useSideBarMenu = () => {
  const [user] = useContext(UserContext);
  const [list, setList] = useState<SideBarNavItem[]>([]);

  useEffect(() => {
    const localList = List<SideBarNavItem>(
      _.cloneDeep(sidebarConfig).filter((menu) => {
        if (menu.show) return menu.show(user);
        if ('links' in menu) {
          menu.links = menu.links.filter((link) => {
            if (link.show) return link.show(user);
            return true;
          });
        }
        return menu;
      })
    );
    setList(localList.toArray());
  }, [user, setList]);

  return list;
};
