import { useState } from 'react';
import { useDeviceUserIndexSceneQuery } from '../generated/graphql';
import { ReactMultiSelectOption } from '../releox-engine/react/form-inputs/3rd-party/react-select/ReactMultiSelect';

export const useDeviceUserReactSelectOptions = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [options, setOptions] = useState<ReactMultiSelectOption[] | null>(null);

  useDeviceUserIndexSceneQuery({
    onCompleted: (data) => {
      if (data.deviceUsers) {
        setOptions(
          data.deviceUsers.list.map((deviceUsers) => ({
            label: deviceUsers.name,
            value: deviceUsers.id,
          }))
        );
      }
    },
  });

  return options;
};
