import { useTranslation } from 'react-i18next';
import { Button } from './Button';

type Props = {
  loading: boolean;
  text?: string;
};

export const SubmitButton = ({ loading, text }: Props) => {
  const { t } = useTranslation();
  return (
    <Button type="submit" loading={loading} className="float-right">
      {text || t('Common:save')}
    </Button>
  );
};
