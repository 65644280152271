import { deviceInput } from '../inputs/device.input';
import { createForm } from '../releox-engine/form/FormGenerator';
import { FormInput } from '../releox-engine/form/FormInput';
import { ReactMultiSelectOption } from '../releox-engine/react/form-inputs/3rd-party/react-select/ReactMultiSelect';

const form: FormInput[] = [
  deviceInput.hardwareIdText,
  deviceInput.name,
  deviceInput.isNotificationMuted,
  deviceInput.maintainers,
];

export const deviceEditForm = createForm(form);

export type DeviceEditFormBody = {
  name: string;
  hardwareId: string;
  isNotificationMuted: boolean;
  maintainers: ReactMultiSelectOption[];
};
