import { Yup } from '../config/yup.instance';
import { FormInput } from '../releox-engine/form/FormInput';

const email: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().email(),
  props: {
    name: 'email',
    label: 'User:email',
    type: 'email',
    autoComplete: 'email',
  },
};

const firstName: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string(),
  props: {
    name: 'firstName',
    label: 'User:firstName',
    type: 'text',
  },
};

const lastName: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string(),
  props: {
    name: 'lastName',
    label: 'User:lastName',
    type: 'text',
  },
};

export const userFilterInput = {
  email,
  firstName,
  lastName,
};
