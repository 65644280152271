export const ForgotSuccessSceneTranslation = {
  fi: {
    ForgotSuccessScene: {
      passwordResetRequest: 'Salasanan nollauspyyntö lähetetty!',
      passwordResetLink:
        'Salasanan nollauslinkki on lähetetty sähköpostiisi. Avaa sähköpostissa oleva linkki ja vaihda salasana.',
      backToLogin: 'Takaisin kirjautumissivulle',
    },
  },
  en: {
    ForgotSuccessScene: {
      passwordResetRequest: 'Password reset request sent!',
      passwordResetLink:
        'A password reset link has been sent to your email. Open the link in the email and change your password.',
      backToLogin: 'Back to login page',
    },
  },
};
