export const EventTranslation = {
  fi: {
    Event: {
      id: '#',
      log: 'Tapahtuma',
      departmentName: 'Osasto',
      deviceTimestamp: 'Aika',
    },
  },
};
