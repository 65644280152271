import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { ReleoxBaseInputProps } from '../../form/ReleoxBaseInputProps';
import { ErrorMessage } from './ErrorMessage';
import { InputWrapper } from './InputWrapper';
import { Label } from './Label';
import { useInputClasses } from './use-input-classes';

export interface TextareaInputProps extends ReleoxBaseInputProps {
  label: string;
}

export const TextareaInput = (props: TextareaInputProps) => {
  const { t } = useTranslation();
  const [field, meta] = useField(props);
  const { name, label } = props;
  const id = `${name}-input`;

  const inputClasses = useInputClasses(meta);

  return (
    <div>
      <Label htmlFor={id} meta={meta}>
        {label}
      </Label>
      <InputWrapper>
        <textarea rows={6} {...props} {...field} placeholder={t(label)} className={inputClasses} />

        <ErrorMessage name={field.name} />
      </InputWrapper>
    </div>
  );
};

export interface GeneratableTextareaInputOptions {
  type: 'textarea';
  initValue: string;
  validation: Yup.StringSchema | Yup.NumberSchema;
  props: TextareaInputProps;
}

export const GeneratableTextareaInput = {
  Element: TextareaInput,
  type: 'textarea',
};
