import { Yup } from '../config/yup.instance';
import { FormInput } from '../releox-engine/form/FormInput';

const email: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().email().required(),
  props: {
    name: 'email',
    label: 'User:email',
    type: 'email',
    autoComplete: 'email',
  },
};

const firstName: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'firstName',
    label: 'User:firstName',
    type: 'text',
  },
};

const lastName: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'lastName',
    label: 'User:lastName',
    type: 'text',
  },
};

const password: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().min(8).required(),
  props: {
    name: 'password',
    label: 'User:password',
    type: 'password',
    autoComplete: 'current-password',
  },
};

const passwordConfirm: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Pin must match')
    .required(),
  props: {
    type: 'password',
    name: 'passwordConfirm',
    label: 'User:passwordConfirm',
  },
};

export const userInput = {
  email,
  password,
  passwordConfirm,
  firstName,
  lastName,
};
