import { useTranslation } from 'react-i18next';
import { getRoute } from '../../config/routes.config';
import { departmentForm } from '../../forms/department.form';
import { useCreateDepartmentMutation } from '../../generated/graphql';
import { useOnComplete } from '../../hooks/use-on-complete';
import { useSubmitHandler } from '../../releox-engine/hooks/use-submit-handler/use-submit-handler';
import { AppFormScene } from '../../releox-engine/react/app-form-scene/AppFormScene';

export const DepartmentCreateScene = (): JSX.Element => {
  const { t } = useTranslation('DepartmentCreateScene');
  const onComplete = useOnComplete({ route: 'DEPARTMENT_INDEX' });
  const [createDepartment, { loading }] = useCreateDepartmentMutation({
    onCompleted: onComplete,
  });

  const handleSubmit = useSubmitHandler(createDepartment);

  return (
    <AppFormScene
      type="create"
      title={t('title')}
      form={departmentForm}
      isSubmitLoading={loading}
      backLink={getRoute('DEPARTMENT_INDEX')}
      onSubmit={handleSubmit}
    />
  );
};
