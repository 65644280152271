import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { getRoute } from '../../config/routes.config';
import { departmentForm } from '../../forms/department.form';
import {
  useDeleteDepartmentMutation,
  useDepartmentEditSceneQuery,
  useUpdateDepartmentMutation,
} from '../../generated/graphql';
import { useId } from '../../hooks/use-id';
import { useOnComplete } from '../../hooks/use-on-complete';
import { useSubmitHandler } from '../../releox-engine/hooks/use-submit-handler/use-submit-handler';
import { AppFormScene } from '../../releox-engine/react/app-form-scene/AppFormScene';

export const DepartmentEditScene = (): JSX.Element => {
  const { t } = useTranslation('DepartmentEditScene');
  const onCompleteUpdate = useOnComplete({
    route: 'DEPARTMENT_INDEX',
  });
  const onCompleteDelete = useOnComplete({
    route: 'DEPARTMENT_INDEX',
    notification: 'deleted',
  });
  const id = useId();
  const query = useDepartmentEditSceneQuery({ variables: { id } });

  const [deleteDepartment] = useDeleteDepartmentMutation({
    onCompleted: onCompleteDelete,
  });

  const [updateDepartment, { loading }] = useUpdateDepartmentMutation({
    onCompleted: onCompleteUpdate,
  });

  const handleSubmit = useSubmitHandler(updateDepartment, { id });

  const handleDelete = useCallback(() => {
    deleteDepartment({ variables: { id } });
  }, [deleteDepartment, id]);

  return (
    <AppFormScene
      type="edit"
      query={query}
      queryDataPath="department"
      title={t('title')}
      form={departmentForm}
      isSubmitLoading={loading}
      onSubmit={handleSubmit}
      backLink={getRoute('DEPARTMENT_INDEX')}
      onDelete={handleDelete}
      onDeletePrompt={t('areYouSure')}
    />
  );
};
