import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthCard } from '../../../components/auth/AuthCard';
import { AuthCardTitle } from '../../../components/auth/AuthCardTitle';
import { AuthError } from '../../../components/auth/AuthError';
import { AuthLayout } from '../../../components/auth/AuthLayout';
import { AuthSubTitle } from '../../../components/auth/AuthSubTitle';
import { ButtonFooter } from '../../../components/auth/ButtonFooter';
import { Loading } from '../../../components/Loading';
import { getRoute } from '../../../config/routes.config';
import { resetPasswordForm, ResetPasswordFormBody } from '../../../forms/auth/reset-password.form';
import {
  useCompleteInvitationMutation,
  useValidateInvitationTokenLazyQuery,
} from '../../../generated/graphql';
import { AppFormGenerator } from '../../../releox-engine/react/app-form-generator/AppFormGenerator';
import { ParseUrl } from '../../../utils/parser-url';

export const AcceptInvitationScene = () => {
  const { t, i18n } = useTranslation('AcceptInvitationScene');
  const [token, setToken] = useState('');
  const [hasError, setHasError] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [validateInvitationToken, { loading: queryLoading, error }] =
    useValidateInvitationTokenLazyQuery();

  useEffect(() => {
    (async () => {
      try {
        const validateToken = ParseUrl.parseLocation(location, 'inviteToken');

        const response = await validateInvitationToken({
          variables: { body: { invitationToken: validateToken } },
        });

        if (response.data?.validateInvitationToken) {
          i18n.changeLanguage(response.data?.validateInvitationToken.language);
          setToken(validateToken);
        } else {
          setHasError(true);
        }
      } catch (e) {
        setHasError(true);
      }
    })();
  }, [location, setHasError, setToken, validateInvitationToken, i18n]);

  const [completeInvitation, { loading }] = useCompleteInvitationMutation({
    onCompleted: (data) => {
      if (data.completeInvitation) {
        toast.success(t('Common:saved'));
        navigate(getRoute('LOGIN'));
      }
    },
  });

  const handleSubmit = useCallback(
    (body: ResetPasswordFormBody) => {
      completeInvitation({
        variables: {
          body: {
            password: body.password,
            invitationToken: token,
          },
        },
      });
    },
    [completeInvitation, token]
  );

  useEffect(() => {
    if (error) {
      setHasError(true);
    }
  }, [error, hasError]);

  if (hasError) {
    return <AuthError loginRoute={getRoute('LOGIN')} errorReason="Invalid token" />;
  }

  if (queryLoading || !token) return <Loading />;

  return (
    <AuthLayout className="py-80" title={t('newPassword')}>
      <AuthCard>
        <div className="mb-5">
          <AuthCardTitle>{t('newPassword')}</AuthCardTitle>
          <AuthSubTitle>{t('fillNewPassword')}</AuthSubTitle>
        </div>
        <AppFormGenerator
          form={resetPasswordForm}
          onSubmit={handleSubmit}
          wrapperClassNames="space-y-3"
          FooterElement={<ButtonFooter buttonText={t('save')} loading={loading} />}
        />
      </AuthCard>
    </AuthLayout>
  );
};
