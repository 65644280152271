import { QueryResult } from '@apollo/client';
import { Map } from 'immutable';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Page } from '../../../components/Page';
import { ReleoxForm } from '../../form/FormGenerator';
import { AppFormGenerator, AppFormGeneratorProps } from '../app-form-generator/AppFormGenerator';
import { DeleteButton } from '../buttons/DeleteButton';
import { BackButtonLink } from '../buttons/LinkButton';
import { SubmitButton } from '../buttons/SubmitButton';
import { appFormSceneConfig } from './app-form-scene-config';

type BaseProps = {
  title: string;
  form: ReleoxForm;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit(data: any): void;

  isSubmitLoading: boolean;
  backLink?: string;
  FooterElement?: AppFormGeneratorProps['FooterElement'];
  HeaderElement?: AppFormGeneratorProps['HeaderElement'];
};

interface CreateProps extends BaseProps {
  type: 'create';
}

interface EditProps extends BaseProps {
  type: 'edit';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  query: QueryResult<any, any>;
  queryDataPath: string;
  onDelete?(): void;
  onDeletePrompt?: string;
}

/**
 *  reformat some value that they fit to formik initial values
 *  like reformat date and remove undefined and null values.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const format = (data: any) => {
  let values = Map(data);

  Object.keys(values.toObject()).forEach((key) => {
    const value = [null, undefined].includes(data[key]) ? '' : data[key];
    values = values.set(key, value);

    // Refactor date format from YYYY-MM-DDTmm:ss to YYYY-MM-DD what is requirements of html date input
    const isDateFormat = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{3}Z$/;
    if (typeof data[key] === 'string' && isDateFormat.test(data[key])) {
      values = values.set(key, data[key].substring(0, 10));
    }
  });

  // Remove graphql ___typename
  values = values.delete('__typename');
  return values.toObject();
};

export const AppFormScene = (props: EditProps | CreateProps) => {
  const { t } = useTranslation('Common');
  const { form, onSubmit, isSubmitLoading, title, backLink } = props;

  // Handle on submit event
  const submitMiddleware = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (baseValues: any) => {
      const validatedValues = form.meta.validateSchema.validateSync(baseValues);

      onSubmit(validatedValues);
    },
    [form, onSubmit]
  );

  const onDeletePrompt = useCallback(() => {
    if (props.type === 'edit' && props.onDelete) {
      if (confirm(props.onDeletePrompt)) {
        props.onDelete();
      }
    }
  }, [props]);

  if (props.type === 'edit' && props.query?.loading) return <appFormSceneConfig.Loading />;

  let key: string | undefined = undefined;

  if (props.type === 'edit') key = JSON.stringify(props.query?.data);

  const Header = props.HeaderElement || (
    <appFormSceneConfig.Title>{title}</appFormSceneConfig.Title>
  );

  const Buttons = props.FooterElement || (
    <div>
      <SubmitButton text={t('save')} loading={isSubmitLoading} />
      {backLink ? <BackButtonLink to={backLink} /> : null}
      {props.type === 'edit' && props.onDelete ? (
        <DeleteButton onClick={onDeletePrompt} loading={isSubmitLoading} />
      ) : undefined}
      <div className="clear-both" />
    </div>
  );

  return (
    <Page title={title}>
      <appFormSceneConfig.Layout key={key}>
        <AppFormGenerator
          wrapperClassNames="space-y-4"
          HeaderElement={Header}
          FooterElement={Buttons}
          onSubmit={submitMiddleware}
          form={form}
          initialValues={
            props.type === 'edit' && props.query?.data && props.queryDataPath
              ? format(props.query.data[props.queryDataPath])
              : {}
          }
        />
      </appFormSceneConfig.Layout>
    </Page>
  );
};
