import { Route, Routes } from 'react-router-dom';
import { RequireAuth } from '../components/RequireAuth';
import { RequireGuest } from '../components/RequireGuest';
import { getDashboardRouteConfig } from '../config/routes.config';
import { DeviceUserFilterContextProvider } from '../contexts/DeviceUserFilterContext';
import { EventFilterContextProvider } from '../contexts/EventFilterContext';
import { UserFilterContextProvider } from '../contexts/UserFilterContext';
import { AcceptInvitationScene } from '../scenes/auth/accept-invitation/AcceptInvitationScene';
import { ForgotScene } from '../scenes/auth/forgot/ForgotScene';
import { ForgotSuccessScene } from '../scenes/auth/forgot/ForgotSuccessScene';
import { LoginScene } from '../scenes/auth/login/LoginScene';
import { LogoutScene } from '../scenes/auth/logout/Logout';
import { ResetPasswordScene } from '../scenes/auth/reset/ResetPasswordScene';
import { ResetPasswordSuccessScene } from '../scenes/auth/reset/ResetPasswordSuccessScene';
import { DashboardRouter } from './DashboardRouter';

const routes = getDashboardRouteConfig();

export const AuthRouter = () => {
  return (
    <Routes>
      <Route
        path={routes.ACCEPT_INVITATION}
        element={
          <RequireGuest>
            <AcceptInvitationScene />
          </RequireGuest>
        }
      />
      <Route
        path={routes.LOGIN}
        element={
          <RequireGuest>
            <LoginScene />
          </RequireGuest>
        }
      />
      <Route
        path={routes.FORGOT}
        element={
          <RequireGuest>
            <ForgotScene />
          </RequireGuest>
        }
      />
      <Route
        path={routes.FORGOT_SUCCESS}
        element={
          <RequireGuest>
            <ForgotSuccessScene />
          </RequireGuest>
        }
      />
      <Route
        path={routes.RESET_PASSWORD}
        element={
          <RequireGuest>
            <ResetPasswordScene />
          </RequireGuest>
        }
      />
      <Route
        path={routes.RESET_PASSWORD_SUCCESS}
        element={
          <RequireGuest>
            <ResetPasswordSuccessScene />
          </RequireGuest>
        }
      />
      <Route path={routes.LOGOUT} element={<LogoutScene />} />
      <Route
        path="*"
        element={
          <RequireAuth>
            <DeviceUserFilterContextProvider>
              <EventFilterContextProvider>
                <UserFilterContextProvider>
                  <DashboardRouter />
                </UserFilterContextProvider>
              </EventFilterContextProvider>
            </DeviceUserFilterContextProvider>
          </RequireAuth>
        }
      />
    </Routes>
  );
};
