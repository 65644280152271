import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getRoute } from '../config/routes.config';

type TNotifications = 'deleted' | 'saved' | 'none';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Callback<T> = (data: T) => any;

type Options<T> = {
  route?: Parameters<typeof getRoute>[0];
  url?: string;
  callback?: Callback<T>;
  remapRouteData?: Callback<T>;
  notification?: TNotifications;
};

export const useOnComplete = <T>(options: Options<T>) => {
  const { url, callback, remapRouteData, route, notification = 'saved' } = options;
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (data: T) => {
    if (data) {
      if (_.keys(data).length !== 1) throw new Error('[useOnComplete] there is more than one key');
      const key = _.keys(data)[0];
      if (callback) callback(data);
      if (notification !== 'none') toast.success(t(`Common:${notification}`));
      if (route) {
        let d = _.get(data, key);
        if (remapRouteData) {
          d = _.map(d, remapRouteData);
        }
        navigate(url || getRoute(route, d, true));
      }
      if (url) {
        navigate(url);
      }
    }
  };
};
