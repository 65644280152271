import _ from 'lodash';
import queryString from 'query-string';
import { Location } from 'react-router';

export class ParseUrl {
  public static parseLocation(location: Location, key: string): string {
    const query = queryString.parse(location.search);

    const value = _.get(query, key);

    if (!value || Array.isArray(value)) throw new Error(`[ParseUrl:parseLocation] Missing ${key}`);

    return value;
  }

  public static parseOptionalLocation(location: Location, key: string): string | null {
    const query = queryString.parse(location.search);

    const value = _.get(query, key);

    if (!value) return null;

    if (Array.isArray(value)) throw new Error(`[ParseUrl:parseLocation] Values is array`);

    return value;
  }
}
