import { ApolloProvider } from '@apollo/client';
import { ToastContainer } from 'react-toastify';
import { useApolloClient } from './hooks/use-apollo-client';
import { AuthRouter } from './routers/AuthRouter';

export const App = () => {
  const apolloClient = useApolloClient();

  if (!apolloClient) return <span />;

  return (
    <ApolloProvider client={apolloClient}>
      <AuthRouter />
      <ToastContainer />
    </ApolloProvider>
  );
};
