import { createContext, useState } from 'react';
import { ChildrenProp } from '../types/ChildrenProp';
import { FilterContext } from '../types/FilterContext';

export type UserFilterContextData = {
  firstName?: string;
  lastName?: string;
  email?: string;
};

export const UserFilterContext = createContext<FilterContext<UserFilterContextData>>([
  {},
  () => ({}),
]);

export const UserFilterContextProvider = ({ children }: ChildrenProp) => {
  const state = useState<UserFilterContextData>({});

  return <UserFilterContext.Provider value={state}>{children}</UserFilterContext.Provider>;
};
