import { FieldMetaProps } from 'formik';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useInputClasses = (meta: FieldMetaProps<any>) => {
  const focus = 'focus:border-primary focus:ring-primary'; // 'focus:outline-none focus:ring-none focus:border-color-primary';
  const border = 'border border-gray-300'; //'';

  const base = 'appearance-none block w-full px-3 py-2 shadow-sm placeholder-gray-400  sm:text-sm';

  const classes = [focus, border, base];

  if (meta.error && meta.touched) {
    classes.push(' border-red-500');
  }

  return classes.join(' ');
};
