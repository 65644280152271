import { Dialog, Disclosure, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import { getRoute } from '../config/routes.config';
import { SideBarNavItem } from '../hooks/use-sidebar-menu';
import LogoBlue from '../images/mediclaudo-logo.png';
import Logo from '../images/mediclaudo.png';
import { joinClassNames } from '../utils/join-class-names';
import { BackgroundClass } from './BackgroundClass';

const notActiveClass =
  'text-white hover:text-primary-700 hover:font-extrabold hover:bg-primary-100 hover:rounded-none';
const notActiveIconClass = '';
const activeClass =
  'bg-primary-100 text-primary-700 font-extrabold hover:bg-secondary-light rounded-none';
const activeIconClass = '';
const sidebarBackground = 'bg-primary-400';

type ChildrenElement = JSX.Element | string | null | (JSX.Element | string | null)[];

export type DashbaordNavItem = {
  name: string;
  href: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any;
  current?: boolean;
  preventActive?: boolean;
  exact?: boolean;
};

type Props = {
  children: JSX.Element | JSX.Element[];
  homeUrl: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user: any;
  nav: SideBarNavItem[];
};

type SidebarLinkProps = {
  to: string;
  children: ChildrenElement;
  baseClass: string;
  exact?: boolean;
  onClick(): void;
};

const SidebarLink = (props: SidebarLinkProps) => {
  const resolved = useResolvedPath(props.to);
  const match = useMatch({ path: resolved.pathname, end: props.exact || false });

  let dynamicClass = notActiveClass;

  if (match) dynamicClass = activeClass;

  return (
    <Link
      onClick={props.onClick}
      className={[dynamicClass, props.baseClass].join(' ')}
      to={props.to}
    >
      {props.children}
    </Link>
  );
};

type DropdownProps = {
  children: ChildrenElement;
  to: string;
  baseClass: string;
  onClick(): void;
  links: SideBarNavItem[];
};

const Dropdown = (props: DropdownProps) => {
  const { t } = useTranslation('SideBar');
  const resolved = useResolvedPath(props.to);
  const match = useMatch({ path: resolved.pathname, end: false });

  const dropDownBase = 'ml-12';

  let dynamicClass = notActiveClass;

  if (match) dynamicClass = activeClass;

  return (
    <Disclosure as="div" className="space-y-1">
      {() => (
        <>
          <Disclosure.Button
            className={[dynamicClass, props.baseClass.replace('w-auto', 'w-full')].join(' ')}
          >
            {props.children}
          </Disclosure.Button>
          <Disclosure.Panel className="space-y-1">
            {props.links.map((subItem) => (
              <Disclosure.Button
                key={subItem.href}
                as={Link}
                onClick={props.onClick}
                to={subItem.href}
                className={[dropDownBase, notActiveClass, props.baseClass].join(' ')}
              >
                <>
                  {subItem.icon ? (
                    <subItem.icon className={'mr-3 flex-shrink-0 h-6 w-6'} aria-hidden="true" />
                  ) : null}
                  {t(subItem.name)}
                </>
              </Disclosure.Button>
            ))}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export const DashboardLayout = (props: Props) => {
  const { children, nav } = props;
  const { t } = useTranslation('SideBar');
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div>
      <BackgroundClass className="bg-gray-100" />
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-primary" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto bg-primary">
                <div className="ml-4">
                  <Link to={getRoute('DASHBOARD')}>
                    <img className="h-10 w-auto" src={Logo} alt="Logo" />
                  </Link>
                </div>
                <nav className="mt-5 px-2 space-y-1">
                  {nav.map((item) =>
                    !('links' in item) ? (
                      <SidebarLink
                        exact={item.exact}
                        onClick={() => setSidebarOpen(false)}
                        key={item.name}
                        to={item.href}
                        baseClass="group flex items-center px-2 py-2 text-base font-medium"
                      >
                        {item.icon ? (
                          <item.icon
                            className={joinClassNames(
                              item.current ? activeIconClass : notActiveIconClass,
                              'mr-4 flex-shrink-0 h-6 w-6'
                            )}
                            aria-hidden="true"
                          />
                        ) : null}
                        {t(item.name)}
                      </SidebarLink>
                    ) : (
                      <Dropdown
                        links={item.links}
                        baseClass="group flex items-center px-2 py-2 text-base font-medium w-auto"
                        key={item.name}
                        to={item.href}
                        onClick={() => setSidebarOpen(false)}
                      >
                        {item.icon ? (
                          <item.icon
                            className={joinClassNames('mr-3 flex-shrink-0 h-6 w-6')}
                            aria-hidden="true"
                          />
                        ) : null}
                        {t(item.name)}
                      </Dropdown>
                    )
                  )}
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14">
            {/* Force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className={joinClassNames(`flex-1 flex flex-col min-h-0`, sidebarBackground)}>
          <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
            <div>
              <Link to={getRoute('DASHBOARD')}>
                <img className="h-8 w-auto m-auto" src={Logo} alt="Logo" />
              </Link>
            </div>
            <nav className="mt-5 flex-1 px-2 space-y-1">
              {nav.map((item) =>
                !('links' in item) ? (
                  <SidebarLink
                    exact={item.exact}
                    onClick={() => setSidebarOpen(false)}
                    baseClass="group flex items-center px-2 py-2 text-sm font-medium"
                    key={item.name}
                    to={item.href}
                  >
                    {item.icon ? (
                      <item.icon
                        className={joinClassNames(
                          item.current ? activeIconClass : notActiveIconClass,
                          'mr-3 flex-shrink-0 h-6 w-6'
                        )}
                        aria-hidden="true"
                      />
                    ) : null}
                    {t(item.name)}
                  </SidebarLink>
                ) : (
                  <Dropdown
                    links={item.links}
                    baseClass="group flex items-center px-2 py-2 text-sm font-medium w-auto"
                    key={item.name}
                    to={item.href}
                    onClick={() => setSidebarOpen(false)}
                  >
                    <item.icon
                      className={joinClassNames('mr-3 flex-shrink-0 h-6 w-6')}
                      aria-hidden="true"
                    />
                    {t(item.name)}
                  </Dropdown>
                )
              )}
            </nav>
          </div>
        </div>
      </div>
      <div className="md:pl-64 flex flex-col flex-1">
        <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow md:hidden">
          <button
            type="button"
            className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="my-auto ml-4">
            <Link to={getRoute('DASHBOARD')}>
              <img className="h-8 w-auto" src={LogoBlue} alt="Logo" />
            </Link>
          </div>
        </div>
        <main className="flex-1">
          <div className="p-2 sm:p-3">{children}</div>
        </main>
      </div>
    </div>
  );
};
