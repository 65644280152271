/* eslint-disable no-console */
import chalk from 'chalk';
import _ from 'lodash';

export class CustomLogger {
  colors = [
    '#00FFFF',
    '#0000FF',
    '#808080',
    '#008000',
    '#00FF00',
    '#800000',
    '#000080',
    '#808000',
    '#800080',
    '#FF0000',
    '#C0C0C0',
    '#008080',
    '#FFFF00',
    '#9B111E',
  ];

  private hex: string;

  constructor(private name: string) {
    const hex = _.sample(this.colors);
    if (!hex) throw new Error('Color error');
    this.hex = hex;
  }

  private getNS() {
    return chalk.hex(this.hex).bold([this.name]);
  }

  log(message: string) {
    console.log(`${this.getNS()} ${message}`);
  }
}
