export const userTranslation = {
  fi: {
    User: {
      email: 'Sähköposti',
      password: 'Salasana',
      id: '#',
      name: 'Nimi',
      lastName: 'Sukunimi',
      firstName: 'Etunimi',
      passwordConfirm: 'Vahvista salasana',
    },
  },
};
