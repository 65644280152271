import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CardTitle } from '../../components/CardTitle';
import { Loading } from '../../components/Loading';
import { getRoute } from '../../config/routes.config';
import { deviceUserInfoForm } from '../../forms/device-user-info.form';
import {
  useDeleteDeviceUserMutation,
  useDeviceUserInfoEditQuery,
  useUpdateDeviceUserMutation,
} from '../../generated/graphql';
import { useId } from '../../hooks/use-id';
import { useOnComplete } from '../../hooks/use-on-complete';
import { useSubmitHandler } from '../../releox-engine/hooks/use-submit-handler/use-submit-handler';
import { AppFormGenerator } from '../../releox-engine/react/app-form-generator/AppFormGenerator';
import { Button } from '../../releox-engine/react/buttons/Button';
import { BackButtonLink } from '../../releox-engine/react/buttons/LinkButton';
import { SubmitButton } from '../../releox-engine/react/buttons/SubmitButton';

export const DeviceUserInfoEditScene = (): JSX.Element => {
  const { t } = useTranslation('DeviceUserInfoEditScene');
  const onCompleteUpdate = useOnComplete({
    route: 'DEVICE_USER_INDEX',
  });

  const onCompleteDelete = useOnComplete({
    route: 'DEVICE_USER_INDEX',
    notification: 'deleted',
  });

  const id = useId();
  const { data, loading: queryLoading } = useDeviceUserInfoEditQuery({ variables: { id } });

  const [deleteDeviceUser, { loading: deleteLoading }] = useDeleteDeviceUserMutation({
    onCompleted: onCompleteDelete,
  });

  const [updateDeviceUser, { loading }] = useUpdateDeviceUserMutation({
    onCompleted: onCompleteUpdate,
  });

  const handleSubmit = useSubmitHandler(updateDeviceUser, { id });

  const handleDelete = useCallback(() => {
    if (confirm(t('areYouSure'))) {
      deleteDeviceUser({ variables: { id } });
    }
  }, [deleteDeviceUser, id, t]);

  const Footer = (
    <>
      <BackButtonLink to={getRoute('DEVICE_USER_INDEX')} />
      <SubmitButton loading={loading || deleteLoading} />
      <hr className="my-2" />
      <Button type="button" onClick={handleDelete} loading={loading || deleteLoading}>
        {t('Common:deleteText')}
      </Button>
    </>
  );

  if (queryLoading) return <Loading />;

  return (
    <>
      <CardTitle>{t('title')}</CardTitle>
      <AppFormGenerator
        initialValues={data?.deviceUser}
        FooterElement={Footer}
        wrapperClassNames="space-y-4"
        form={deviceUserInfoForm}
        onSubmit={handleSubmit}
      />
    </>
  );
};
