import { useTranslation } from 'react-i18next';

export const Loading = () => {
  const { t } = useTranslation('Common');
  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8 items-center">
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="flex items-center justify-center ">
          <div className="w-16 h-16 border-b-2 border-primary rounded-full animate-spin"></div>
        </div>
        <p className="text-center mt-12 text-sm text-primary">{t('loading')}</p>
      </div>
    </div>
  );
};
