import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AuthCard } from '../../../components/auth/AuthCard';
import { AuthCardTitle } from '../../../components/auth/AuthCardTitle';
import { AuthLayout } from '../../../components/auth/AuthLayout';
import { AuthSubTitle } from '../../../components/auth/AuthSubTitle';
import { getRoute } from '../../../config/routes.config';

export const ResetPasswordSuccessScene = () => {
  const { t } = useTranslation('ResetPasswordSuccessScene');
  return (
    <AuthLayout className="py-96" title={t('passwordChanged')}>
      <AuthCard>
        <div className="mb-5">
          <AuthCardTitle>{t('passwordChanged')}</AuthCardTitle>
          <AuthSubTitle>{t('successfullyChanged')}</AuthSubTitle>
        </div>
        <Link to={getRoute('LOGIN')} className="text-primary hover:text-primary-500 block text-sm ">
          {t('backToLogin')}
        </Link>
      </AuthCard>
    </AuthLayout>
  );
};
