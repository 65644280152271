export const ForgotSceneTranslation = {
  fi: {
    ForgotScene: {
      backToLogin: 'Takaisin kirjautumissivulle',
      resetPasswordBtn: 'Nollaa',
      resetPassword: 'Nollaa salasana',
      fillYourEmail:
        'Kirjoita sähköpostiosoitteesi niin lähetämme sinulle linkin, jolla voit vaihtaa salasanasi.',
    },
  },
  en: {
    ForgotScene: {
      backToLogin: 'Back to login page',
      resetPasswordBtn: 'Reset',
      resetPassword: 'Reset password',
      fillYourEmail: "Enter your email address and we'll send you a link to change your password.",
    },
  },
};
