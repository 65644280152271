import { deleteTranslation } from '../../utils/delete-translation';

export const DepartmentEditSceneTranslation = {
  fi: {
    DepartmentEditScene: {
      title: 'Muokkaa osastoa',
      areYouSure: deleteTranslation('osaston'),
    },
  },
};
