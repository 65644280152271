import _ from 'lodash';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { passwordForm, PasswordFormBody } from '../../forms/password.form';
import { useUpdatePasswordMutation } from '../../generated/graphql';
import { AppFormScene } from '../../releox-engine/react/app-form-scene/AppFormScene';

export const ChangePasswordScene = (): JSX.Element => {
  const { t } = useTranslation('ChangePasswordScene');
  const [updatePassword, { loading }] = useUpdatePasswordMutation({
    onCompleted: (d) => {
      if (d.updatePassword) {
        toast.success(t('Common:saved'));
      }
    },
  });

  const handleSubmit = useCallback(
    (body: PasswordFormBody) => {
      updatePassword({ variables: { body: _.omit(body, 'passwordConfirm') } });
    },
    [updatePassword]
  );

  return (
    <AppFormScene
      type="create"
      title={t('title')}
      form={passwordForm}
      isSubmitLoading={loading}
      onSubmit={handleSubmit}
    />
  );
};
