import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { ReleoxBaseInputProps } from '../../form/ReleoxBaseInputProps';
import { ErrorMessage } from './ErrorMessage';
import { InputWrapper } from './InputWrapper';
import { Label } from './Label';
import { useInputClasses } from './use-input-classes';

type Autocomplete = 'on' | 'off' | 'name' | 'email' | 'current-password';
type Type = 'text' | 'email' | 'password' | 'number' | 'date';

export interface TextInputProps extends ReleoxBaseInputProps {
  label: string;
  type: Type;
  autoComplete?: Autocomplete;
}

export const TextInput = (props: TextInputProps) => {
  const { t } = useTranslation();
  const [field, meta] = useField(props);
  const { name, label } = props;
  const id = `${name}-input`;

  const inputClasses = useInputClasses(meta);

  return (
    <div>
      <Label htmlFor={id} meta={meta}>
        {label}
      </Label>
      <InputWrapper>
        <input {...props} {...field} placeholder={t(label)} className={inputClasses} />

        <ErrorMessage name={field.name} />
      </InputWrapper>
    </div>
  );
};

export interface GeneratableTextInputOptions {
  type: 'text';
  initValue: string | number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validation: Yup.StringSchema<any> | Yup.DateSchema | Yup.NumberSchema;
  props: TextInputProps;
}

export const GeneratableTextInput = {
  Element: TextInput,
  type: 'text',
};
