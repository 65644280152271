export const ErrorMessageTranslation = {
  fi: {
    ErrorMessage: {
      emailAlreadyExists: 'Sähköposti on jo olemassa',
      notFound: 'Tarkista sähköpostiosoitteesi',
      incorrectCredentials: 'Tarkista sähköpostiosoite ja salasana',
      loginExpired: 'Kirjautuminen on vanhentunut',
      deviceAlreadyExists: 'Laitetunnus on jo olemassa',
      tagAlreadyExists: 'Tagin UID-tunnus on jo olemassa',
    },
  },
};
