import { deleteTranslation } from '../../utils/delete-translation';

export const DeviceUserInfoEditSceneTranslation = {
  fi: {
    DeviceUserInfoEditScene: {
      title: 'Muokkaa käyttäjän tietoja',
      areYouSure: deleteTranslation('käyttäjän'),
    },
  },
};
