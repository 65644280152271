export const ResetPasswordSuccessSceneTranslation = {
  fi: {
    ResetPasswordSuccessScene: {
      passwordChanged: 'Salasanasi on vaihdettu',
      successfullyChanged:
        'Salasanasi on vaihdettu onnistuneesti. Voit nyt palata kirjautumissivulle ja kirjautua uudella salasanalla.',
      backToLogin: 'Kirjautumissivulle',
    },
  },
  en: {
    ResetPasswordSuccessScene: {
      passwordChanged: 'Your password is changed',
      successfullyChanged:
        'Your password has been changed successfully. You can now return to the login page and log in with a new password.',
      backToLogin: 'Back to login page',
    },
  },
};
