import { Form, Formik } from 'formik';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { ReleoxForm } from '../../form/FormGenerator';

type BaseProps = {
  form: ReleoxForm;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit(data: any): void;
};

export interface AppFormGeneratorProps extends BaseProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialValues?: any;
  wrapperClassNames?: string;
  HeaderElement?: JSX.Element;
  FooterElement?: JSX.Element;
}

export const AppFormGenerator = (props: AppFormGeneratorProps) => {
  const { form, initialValues, onSubmit, wrapperClassNames, HeaderElement, FooterElement } = props;
  const [init, setInit] = useState(null);

  useEffect(() => {
    const metaInitClone = _.cloneDeep(form.meta.initValues);
    const initClone = _.cloneDeep(initialValues);

    setInit(_.merge(metaInitClone, initClone || {}));
  }, [form.meta.initValues, initialValues]);

  if (!init) return <span />;

  return (
    <Formik
      enableReinitialize
      initialValues={init}
      validationSchema={form.meta.validateSchema}
      onSubmit={onSubmit}
    >
      <Form className={wrapperClassNames}>
        {HeaderElement}

        <form.Element />

        {FooterElement}
      </Form>
    </Formik>
  );
};
